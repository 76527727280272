
import { Options, Vue } from 'vue-class-component'
import Home from './Home.vue';
import AddToWish from '@/components/AddToWish.vue'

interface Comment {
    id: string;
    content: string;
    date: Date;
    userId: string;
    name: string;
}

interface ServerComment {
    id: string;
    content: string;
    created: number;
    name: string;
}

interface Image {
    id: string;
    image: string | null;
    url: string | null;
}

interface Reservertion {
    id: string;
    num: number;
    userId: string;
    name: string;
}

@Options({
    components: {
        AddToWish
    }
})

export default class WishDetails extends Vue {
    private milaImages = 5;
    public id = '';
    public title = '';
    public description = '';
    public comment = '';
    public date = new Date();
    public comments: Comment[] = [];
    public images: Image[] = [];
    public image = '';
    public reservertions: Reservertion[] = [];
    private dateFormat!: Intl.DateTimeFormat;
    private reloaded = 0;
    private imageIndex = 0;

    created(): void {
        this.id = this.$route.params.id as string;
        this.dateFormat = new Intl.DateTimeFormat('da', { dateStyle: 'short', timeStyle: 'short'} as Intl.DateTimeFormatOptions);
        this.loadDetails();
    }
    loadDetails(): void {
        if (this.reloaded++) {
            Home.wishes = [];
        }
        fetch(`/api/wish/${this.id}`)
            .then(response => response.json())
            .then(json => {
                if (json) {
                    this.title = json.title;
                    this.description = json.description;
                    this.image = json.image;
                    this.date = new Date(json.created * 1000);
                    this.comments = json.comments.map((comment: ServerComment) => {
                        return Object.assign({ date: new Date(comment.created * 1000) }, comment);
                    });
                    this.images = json.images;
                    this.reservertions = json.reservertions;
                } else {
                    this.title = "Ukendt ønske";
                }
            });
    }
    createComment(): void {
        fetch(`/api/comment/${this.id}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: this.userId,
                content: this.comment
            })
        }).then(response => response.json())
        .then(() => {
            this.comment = '';
            this.loadDetails();
        });
    }
    deleteComment(id: string): void {
        fetch(`/api/comment/${id}`, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: this.userId
            })
        }).then(response => response.json()).then(() => this.loadDetails());
    }
    reserve(): void {
        const userId = this.userId;
        if (!userId) {
            throw new Error('Cannot reserve without user.');
        }
        let res = this.reservertions.find(res => res.userId == userId);
        const num = res ? res.num + 1 : 1;
        fetch('/api/reserve', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                num: num,
                id: this.id,
                userId: userId
            })
        })
            .then(response => response.json())
            .then(() => this.loadDetails());
    }
    deleteReservertion(): void {
        fetch('/api/reserve', {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: this.id,
                userId: this.userId
            })
        }).then(response => response.json()).then(() => this.loadDetails());
    }
    prev(): void {
        this.imageIndex = (this.imageIndex - 1 + this.images.length) % this.images.length;
        this.scrollTo();
    }
    next(): void {
        this.imageIndex = (this.imageIndex + 1 + this.images.length) % this.images.length;
        this.scrollTo();
    }
    scrollTo(): void {
        const child = (this.$refs.imageList as HTMLElement).children[this.imageIndex];
        if (child) {
            child.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
        }
    }
    get userId(): string | null {
        return localStorage.getItem('user');
    }
    relativeTime(date: Date): string {
        const seconds = (Date.now() - date.getTime()) / 1000;
        if (seconds < 0) {
            return this.formatTime(date);
        }
        if (seconds < 60) {
            return 'Mindre end et minut siden.';
        }
        let minutes = Math.floor(seconds / 60);
        if (minutes < 60) {
            return `${minutes == 1 ? '1 minut' : minutes + ' minutter'} siden.`;
        }
        const hours = Math.floor(minutes / 60);
        if (hours < 24) {
            minutes = minutes % 60;
            return `${hours == 1 ? '1 time' : hours + ' timer'}${minutes ? ` og ${minutes == 1 ? '1 minut' : minutes + ' minutter'}` : ''} siden`;
        }
        const days = Math.floor(hours / 24);
        if (days < 7) {
            return `${days == 1 ? '1 dag' : days + ' dage '} siden`;
        }
        return this.formatTime(date);
    }
    formatTime(date: Date): string {
        return this.dateFormat.format(date);
    }
    get randomImage(): string {
        const index = Math.floor(Math.random() * this.milaImages);
        return `/api/images/mila${index}.jpg`;
    }
    get reservertionSum(): number {
        return this.reservertions.reduce((prev, res) => prev + res.num, 0);
    }
    get reservertionsText(): string {
        const sum = this.reservertionSum;
        if (sum == 0) {
            return 'Ingen reserveringer';
        } else if (sum == 1) {
            return '1 reservering';
        } else {
            return sum + ' reserveringer';
        }
    }
}
