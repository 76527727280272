
import { Options, Vue } from 'vue-class-component'

@Options({
    emits: ['wishAddCreated'],
    props: {
        id: String
    }
})
export default class AddToWish extends Vue {
    id!: string;
    image = '';
    url = '';

    openDialog(): void {
        (this.$refs.dialog as HTMLDialogElement).setAttribute('open', '');
    }

    closeDialog(): void {
        (this.$refs.dialog as HTMLDialogElement).removeAttribute('open');
    }

    loadImage(): void {
        fetch('/api/wish/load', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({url: this.url})
        }).then(response => response.json())
        .then((json: any) => {
            if (json.image) {
                this.image = json.image;
            }
        });
    }

    save(): void {
        const userId = this.userId;
        if (!userId) {
            throw new Error('User not found');
        }
        const form = this.$refs.form as HTMLFormElement;
        const formData = new FormData(form);
        formData.append('userId', userId);
        formData.append('id', this.id);
        fetch(form.action, {
            method: form.method,
            body: formData
        }).then(response => response.json())
        .then((json) => {
            if (json) {
                this.image = '';
                this.url = '';
                this.closeDialog();
                this.$emit('wishAddCreated', this.id);
            }
        });
    }

    get userId(): string | null {
        return localStorage.getItem('user');
    }
}
