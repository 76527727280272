<template>
    <div class="form-group">
        <button @click="openDialog">Tilføj til gave</button>
        <dialog ref="dialog" @click.self="closeDialog">
            <section>
                <form ref="form" action="/api/wish/addToWish" method="post" enctype="multipart/form-data" @submit.prevent="save">
                    <div class="form-group inline">
                        <input type="text" placeholder="Url" v-model="url" name="url" />
                        <button @click.prevent.stop="loadImage" type="button">Hent billede</button>
                    </div>
                    <div class="form-group" v-if="image">
                        <input type="hidden" name="image" v-model="image" />
                        <a href="#" @click.prevent.stop="image=''">Fjern billede</a>
                        <img :src="image" />
                    </div>
                    <div class="form-group" v-else>
                        <label>Upload billede</label>
                        <input type="file" name="file" />
                    </div>
                    <div class="form-group">
                        <button type="submit">Tilføj til gave</button>
                    </div>
                </form>
            </section>
        </dialog>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
    emits: ['wishAddCreated'],
    props: {
        id: String
    }
})
export default class AddToWish extends Vue {
    id!: string;
    image = '';
    url = '';

    openDialog(): void {
        (this.$refs.dialog as HTMLDialogElement).setAttribute('open', '');
    }

    closeDialog(): void {
        (this.$refs.dialog as HTMLDialogElement).removeAttribute('open');
    }

    loadImage(): void {
        fetch('/api/wish/load', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({url: this.url})
        }).then(response => response.json())
        .then((json: any) => {
            if (json.image) {
                this.image = json.image;
            }
        });
    }

    save(): void {
        const userId = this.userId;
        if (!userId) {
            throw new Error('User not found');
        }
        const form = this.$refs.form as HTMLFormElement;
        const formData = new FormData(form);
        formData.append('userId', userId);
        formData.append('id', this.id);
        fetch(form.action, {
            method: form.method,
            body: formData
        }).then(response => response.json())
        .then((json) => {
            if (json) {
                this.image = '';
                this.url = '';
                this.closeDialog();
                this.$emit('wishAddCreated', this.id);
            }
        });
    }

    get userId(): string | null {
        return localStorage.getItem('user');
    }
}
</script>

<style lang="scss" scoped>
    .form-group {

        button {
            margin: 0 20px 0 auto;
            font-size: 20px;
        }
    }
</style>