<template>
    <article>
        <p class="back"><router-link to="/">ᐊ Tilbage</router-link></p>
        <h1>{{ title }}</h1>
        <time class="created" :datetime="formatTime(date)">{{ relativeTime(date) }}</time>
        <div class="content">{{ description }}</div>
        <div class="images" v-if="images.length" :style="'--index: ' + imageIndex">
            <div class="prev" @click="prev">ᐊ</div>
            <div class="image-container" ref="imageList">
                <template v-for="img of images" :key="img.id">
                    <a :href="img.url" v-if="img.url" target="_blank">
                        <img v-if="img.image" :src="'/api/images/' + img.image" />
                    </a>
                    <img v-else :src="'/api/images/' + img.image" />
                </template>
            </div>
            <div class="next" @click="next">ᐅ</div>
        </div>
        <div class="image" v-else>
            <img v-if="image" :src="'/api/images/' + image" />
            <img v-else v-bind:src="randomImage" />
        </div>
        <AddToWish :id="id" @wishAddCreated="loadDetails" v-if="userId" />
        <div class="meta">
            <h3 v-if="reservertions.length">Reserveringer: {{ reservertionSum }}</h3>
            <div class="form-group" v-if="userId">
                <button @click="reserve">Reserver</button>
            </div>
            <ul v-if="reservertions.length">
                <li v-for="res of reservertions" :key="res.id">
                    <span>{{ res.name }}: {{ res.num }}</span>
                    <button v-if="res.userId == userId" @click="deleteReservertion">Slet reservertion</button>
                </li>
            </ul>
        </div>
        <div v-if="!userId">
            <p>Vælg eller opret bruger for at kommentere, reservere eller tilføje yderligere til gaven.</p>
        </div>
        <div class="comments">
            <h3 v-if="comments.length">Kommentarer</h3>
            <ul v-if="comments.length">
                <li v-for="c of comments" :key="c.id">
                    <h5 class="author">{{ c.name }}</h5>
                    <div class="created">{{ relativeTime(c.date) }}</div>
                    <div class="content">{{ c.content }}</div>
                    <button v-if="c.userId == userId" @click="deleteComment(c.id)">Slet kommentar</button>
                </li>
            </ul>
            <div class="form-group" v-if="userId">
                <textarea v-model="comment" cols="30" rows="10"></textarea>
            </div>
            <div class="form-group" v-if="userId">
                <button @click="createComment" :disabled="comment==''">Tilføj kommentar</button>
            </div>
        </div>
    </article>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Home from './Home.vue';
import AddToWish from '@/components/AddToWish.vue'

interface Comment {
    id: string;
    content: string;
    date: Date;
    userId: string;
    name: string;
}

interface ServerComment {
    id: string;
    content: string;
    created: number;
    name: string;
}

interface Image {
    id: string;
    image: string | null;
    url: string | null;
}

interface Reservertion {
    id: string;
    num: number;
    userId: string;
    name: string;
}

@Options({
    components: {
        AddToWish
    }
})

export default class WishDetails extends Vue {
    private milaImages = 5;
    public id = '';
    public title = '';
    public description = '';
    public comment = '';
    public date = new Date();
    public comments: Comment[] = [];
    public images: Image[] = [];
    public image = '';
    public reservertions: Reservertion[] = [];
    private dateFormat!: Intl.DateTimeFormat;
    private reloaded = 0;
    private imageIndex = 0;

    created(): void {
        this.id = this.$route.params.id as string;
        this.dateFormat = new Intl.DateTimeFormat('da', { dateStyle: 'short', timeStyle: 'short'} as Intl.DateTimeFormatOptions);
        this.loadDetails();
    }
    loadDetails(): void {
        if (this.reloaded++) {
            Home.wishes = [];
        }
        fetch(`/api/wish/${this.id}`)
            .then(response => response.json())
            .then(json => {
                if (json) {
                    this.title = json.title;
                    this.description = json.description;
                    this.image = json.image;
                    this.date = new Date(json.created * 1000);
                    this.comments = json.comments.map((comment: ServerComment) => {
                        return Object.assign({ date: new Date(comment.created * 1000) }, comment);
                    });
                    this.images = json.images;
                    this.reservertions = json.reservertions;
                } else {
                    this.title = "Ukendt ønske";
                }
            });
    }
    createComment(): void {
        fetch(`/api/comment/${this.id}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: this.userId,
                content: this.comment
            })
        }).then(response => response.json())
        .then(() => {
            this.comment = '';
            this.loadDetails();
        });
    }
    deleteComment(id: string): void {
        fetch(`/api/comment/${id}`, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: this.userId
            })
        }).then(response => response.json()).then(() => this.loadDetails());
    }
    reserve(): void {
        const userId = this.userId;
        if (!userId) {
            throw new Error('Cannot reserve without user.');
        }
        let res = this.reservertions.find(res => res.userId == userId);
        const num = res ? res.num + 1 : 1;
        fetch('/api/reserve', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                num: num,
                id: this.id,
                userId: userId
            })
        })
            .then(response => response.json())
            .then(() => this.loadDetails());
    }
    deleteReservertion(): void {
        fetch('/api/reserve', {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: this.id,
                userId: this.userId
            })
        }).then(response => response.json()).then(() => this.loadDetails());
    }
    prev(): void {
        this.imageIndex = (this.imageIndex - 1 + this.images.length) % this.images.length;
        this.scrollTo();
    }
    next(): void {
        this.imageIndex = (this.imageIndex + 1 + this.images.length) % this.images.length;
        this.scrollTo();
    }
    scrollTo(): void {
        const child = (this.$refs.imageList as HTMLElement).children[this.imageIndex];
        if (child) {
            child.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
        }
    }
    get userId(): string | null {
        return localStorage.getItem('user');
    }
    relativeTime(date: Date): string {
        const seconds = (Date.now() - date.getTime()) / 1000;
        if (seconds < 0) {
            return this.formatTime(date);
        }
        if (seconds < 60) {
            return 'Mindre end et minut siden.';
        }
        let minutes = Math.floor(seconds / 60);
        if (minutes < 60) {
            return `${minutes == 1 ? '1 minut' : minutes + ' minutter'} siden.`;
        }
        const hours = Math.floor(minutes / 60);
        if (hours < 24) {
            minutes = minutes % 60;
            return `${hours == 1 ? '1 time' : hours + ' timer'}${minutes ? ` og ${minutes == 1 ? '1 minut' : minutes + ' minutter'}` : ''} siden`;
        }
        const days = Math.floor(hours / 24);
        if (days < 7) {
            return `${days == 1 ? '1 dag' : days + ' dage '} siden`;
        }
        return this.formatTime(date);
    }
    formatTime(date: Date): string {
        return this.dateFormat.format(date);
    }
    get randomImage(): string {
        const index = Math.floor(Math.random() * this.milaImages);
        return `/api/images/mila${index}.jpg`;
    }
    get reservertionSum(): number {
        return this.reservertions.reduce((prev, res) => prev + res.num, 0);
    }
    get reservertionsText(): string {
        const sum = this.reservertionSum;
        if (sum == 0) {
            return 'Ingen reserveringer';
        } else if (sum == 1) {
            return '1 reservering';
        } else {
            return sum + ' reserveringer';
        }
    }
}
</script>

<style lang="scss">
    .back {
        text-align: left;

        a {
            font-size: large;
        }
    }
    time.created {
        font-size: small;
        color: var(--dark-color);
    }
    article .meta {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 20px 0;
        text-align: left;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        grid-template-areas: "header button" "list list";
        align-items: top;

        > * {
            margin: 0;
        }

        h3 {
            grid-area: header;
        }

        .form-group {
            grid-area: button;
        }

        ul {
            margin: 20px 0 0;
            grid-area: list;

            li {
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;


                span {
                    font-size: 20px;
                }
            }
        }

        button {
            font-size: 20px;
            margin: 0;
        }
    }
    article .comments {
        text-align: left;

        li {
            display: grid;
            grid-template-areas: "author date" "content content" "ignore delete";
            grid-template-columns: 1fr auto;
            grid-template-rows: auto auto;
            margin-bottom: 30px;
        }
        h5 {
            font-size: 1rem;
            margin: 0;
            grid-area: author;
            padding: 0 0 5px 10px;
            align-self: end;
        }
        .created {
            font-size: small;
            color: var(--dark-color);
            text-align: right;
            grid-area: date;
            align-self: end;
            padding-bottom: 5px;
        }
        .content {
            grid-area: content;
            white-space: pre-wrap;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
            border-radius: 6px;
            padding: 10px;
        }
        li button {
            grid-area: delete;
            background: var(--important-gradient);
            font-size: 20px;
            margin: 0;
            transition: color 0.15s;

            &::after {
                content: "";
                display: block;
                background: white;
                height: 1px;
                width: 0;
                transition: width 0.15s;
                margin: 0 auto;
            }

            &:hover::after {
                width: 100%;
            }
        }
    }

    .image img,
    .images img {
        width: 300px;
        height: 200px;
        object-fit: contain;
    }

    .images {
        position: relative;
        width: 300px;
        margin: 0 auto;

        .prev,
        .next {
            position: absolute;
            top: 80px;
            font-size: large;
            color: var(--dark-color);
            cursor: pointer;
            padding: 10px 20px;
        }

        .prev {
            left: -20px;
        }

        .next {
            right: -20px;
        }
        
        .image-container {
            overflow: auto;
            display: flex;
            flex-direction: row;
            scroll-snap-type: x mandatory;

            > * {
                scroll-snap-align: center;
            }
        }

        a {
            display: block;
            width: 600px;
        }
    }

    @media (min-width: 652px) {
        .images {
            width: 600px;

            .prev,
            .next {
                font-size: xx-large;
                top: 180px;
            }
        }

        .images img,
        .image img {
            width: 600px;
            height: 400px;
        }
    }
</style>

<style lang="scss" scoped>
    textarea {
        width: 300px !important;
        margin: 0 auto;
    }
</style>